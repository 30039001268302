import { Navigate } from "react-router-dom";
import Layout from "./components/layouts/Layout/Layout";
import ClientsPage from "./pages/admin/Clients/ClientsPage";
import DashboardPage from "./pages/admin/Dashboard/DashboardPage";
import LoginPage from "./pages/Login/LoginPage";
import RegisterPage from "./pages/Register/RegisterPage";
import CompaniesPage from "./pages/admin/Companies/CompaniesPage";
import AddCompanyPage from "./pages/admin/Companies/Add/AddCompanyPage";
import EditCompanyPage from "./pages/admin/Companies/Edit/EditCompanyPage";
import SellersPage from "./pages/admin/Sellers/SellersPage";
import AddSeller from "./pages/admin/Sellers/Add/AddSeller";
import EditSeller from "./pages/admin/Sellers/Edit/EditSeller";
import StockPage from "./pages/admin/Stock/StockPage";
import StockDetailsPage from "./pages/admin/Stock/StockDetails/StockDetailsPage";
import AdvStockPage from "./pages/admin/AdvStock/AdvStockPage";
import SellerDashboardPage from "./pages/seller/Dashboard/DashboardPage";
import ClientDashbordPage from "./pages/client/Dashboard/DashboardPage";
import WebTrackingPage from "./pages/seller/WebTracking/WebTrackingPage";
import SearchResultPage from "./pages/client/SearchResult/SearchResultPage";
import MyCartPage from "./pages/client/MyCart/MyCartPage";
import SearchFilterPage from "./pages/client/SearchFilter/SearchFilterPage";
import HomePage from "./pages/Home/HomePage";
import ThirdPartyPage from "./pages/admin/ThirdParty/ThirdPartyPage";
import AddThirdParty from "./pages/admin/ThirdParty/Add/AddThirdParty";
import EditThirdParty from "./pages/admin/ThirdParty/Edit/EditThirdParty";
import SavedSearch from "./pages/admin/SavedSearch/SavedSearch";
import SavedSearchSellerPage from "./pages/seller/SavedSearchSeller/SavedSearchSeller";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPasswordPage";
import AdminUserPage from "./pages/admin/AdminUser/AdminUserPage";
import AddAdminUser from "./pages/admin/AdminUser/Add/AddAdminUser";
import EditAdminUser from "./pages/admin/AdminUser/Edit/EditAdminUser";

const AdminRoutes = [
    {
        path: "/",
        element: <Navigate to="/home"></Navigate>
    },
    {
        path: 'dashboard',
        element: <DashboardPage />
    },
    {
        path: "clients",
        element: <ClientsPage />
    },
    {
        path: "companies",
        element: <CompaniesPage />
    },
    {
        path: "companies/add",
        element: <AddCompanyPage />
    },
    {
        path: "companies/edit/:id",
        element: <EditCompanyPage />
    },
    {
        path: "sellers",
        element: <SellersPage />
    },
    {
        path: "sellers/add",
        element: <AddSeller />
    },
    {
        path: "sellers/edit/:id",
        element: <EditSeller />
    },
    {
        path: "stock",
        element: <StockPage />
    },
    {
        path: "stock/:id",
        element: <StockDetailsPage />
    },
    {
        path: "advstock",
        element: <AdvStockPage />
    },
    {
        path: "web-tracking",
        element: <WebTrackingPage />
    },
    {
        path: "search",
        element: <SearchFilterPage />
    },
    {
        path: "thirdparty",
        element: <ThirdPartyPage />
    },
    {
        path: "thirdparty/add",
        element: <AddThirdParty />
    },
    {
        path: "thirdparty/edit/:id",
        element: <EditThirdParty />
    },
    {
        path: "saved-search",
        element: <SavedSearch />

    },
    {
        path: "admin-user",
        element: <AdminUserPage />
    },
    {
        path: "admin-user/add",
        element: <AddAdminUser />
    },
    {
        path: "admin-user/edit/:id",
        element: <EditAdminUser />
    },
]

const SellerRoutes = [
    {
        path: "seller/dashboard",
        element: <SellerDashboardPage />
    },
    {
        path: "seller/clients",
        element: <ClientsPage />
    },
    {
        path: "seller/web-tracking",
        element: <WebTrackingPage />
    },
    {
        path: "seller/saved-search",
        element: <SavedSearchSellerPage />

    }
]

const ClientRoutes = [
    {
        path: "client/dashboard",
        element: <ClientDashbordPage />
    },

    {
        path: "client/search",
        element: <SearchFilterPage />
    },
    {
        path: "client/result",
        element: <SearchResultPage />
    },
    {
        path: "client/cart",
        element: <MyCartPage />
    }
];

export const Routes = [
    {
        path: "home",
        element: <HomePage />
    },
    {
        path: "login",
        element: <LoginPage />
    },
    {
        path: "register",
        element: <RegisterPage />
    },
    {
        path: "forgot-password",
        element: <ForgotPasswordPage />
    },
    {
        path: "reset-password/:id",
        element: <ResetPasswordPage />
    },
    {
        path: "/",
        element: <Layout />,
        children: [
            ...AdminRoutes,
            ...SellerRoutes,
            ...ClientRoutes
        ]
    }
]

export default {};