import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider, createMuiTheme, createTheme } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { Routes } from './Routes';
import { ToastContainer } from 'react-toastify';
import MaintenancePage from './pages/Maintenance/MaintenancePage';

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: `"Nunito Sans", sans-serif;`,
  },
  palette: {
    primary: {
      main: "#1A4B71"
    },
  },
});

function App() {
  const router = createBrowserRouter([
    ...Routes,
  ]);

  // const router = createBrowserRouter([
  //   {
  //     path: '*', // Catch-all route
  //     element: <MaintenancePage />,
  //   },
  // ]);

  return <>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
    <ToastContainer />
  </>
}

export default App;
